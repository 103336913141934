<template>
    <div
        class="matchingLibrary_used"
        v-loading="isLoading"
    >
        <div class="header_wrapper">
            <div class="header_left">
                <div
                    class="input_item"
                    v-show="false"
                >
                    <span>配合比编号：</span>
                    <el-input
                        v-model="standardFormula.experiment_formula_number"
                        placeholder="请输入内容"
                        disabled
                    ></el-input>
                </div>
                <div class="input_item">
                    <span class="mustFill">配合比名称：</span>
                    <el-input
                        v-model="standardFormula.experiment_formula_name"
                        placeholder="请输入内容"
                    ></el-input>
                </div>
                <div class="input_item">
                    <span class="mustFill">配方类型：</span>
                    <el-select
                        v-model="standardFormula.formula_type"
                        placeholder="请选择"
                        @change="formulaTypeChange"
                    >
                        <el-option
                            v-for="item in formula_typeData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
            </div>
            <div class="header_right">
                <el-button
                    type="primary"
                    @click="standardformulaSave"
                >
                    保存
                </el-button>
            </div>
        </div>
        <div class="main_wrapper">
            <div class="main_top">
                <div class="product_marking_wrapper">
                    <p>产品标示</p>
                    <ul>
                        <li>
                            <span>强度等级：</span>
                            <el-select
                                v-model="standardFormula.strength_grade"
                                placeholder="请选择"
                                @change="selectGet('strength_grade')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.qddj.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>抗渗等级：</span>
                            <el-select
                                v-model="standardFormula.impervious"
                                placeholder="请选择"
                                @change="selectGet('impervious')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.ksdj.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>抗冻等级：</span>
                            <el-select
                                v-model="standardFormula.antifreeze"
                                placeholder="请选择"
                                @change="selectGet('antifreeze')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.kddj.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>抗折要求：</span>
                            <el-select
                                v-model="standardFormula.flexural"
                                placeholder="请选择"
                                @change="selectGet('flexural')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.kzyq.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                        <li>
                            <span>特殊要求：</span>
                            <el-select
                                v-model="standardFormula.special_require"
                                placeholder="请选择"
                                @change="selectGet('special_require')"
                            >
                                <el-option
                                    v-for="item in ProductIdentification.tsyq.options"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                ></el-option>
                            </el-select>
                        </li>
                    </ul>
                </div>
                <div class="basic_info_wrapper">
                    <p>基本信息</p>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <div class="grid-content bg-purple">
                                <ul>
                                    <li>
                                        <span class="item_title">设计容量(kg)：</span>
                                        <el-input
                                            v-model="standardFormula.design_volume_weight"
                                            placeholder="请输入内容"
                                            disabled
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">水胶比(%)：</span>
                                        <el-input
                                            v-model="standardFormula.water_binder_ratio"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">最大粒径：</span>
                                        <el-input
                                            v-model="standardFormula.max_particle_size"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">施工季节：</span>
                                        <el-select
                                            v-model="standardFormula.season"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in seasonData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                    <li>
                                        <span class="item_title">浇捣方式：</span>
                                        <el-select
                                            v-model="standardFormula.poured"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in pouredData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                    <li>
                                        <span class="item_title">技术要求：</span>
                                        <el-input
                                            v-model="standardFormula.specification"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="grid-content bg-purple-light">
                                <ul>
                                    <li>
                                        <span class="item_title">坍落度(mm)：</span>
                                        <el-input
                                            v-model="standardFormula.slump"
                                            style="width:1.15rem"
                                        ></el-input>
                                        <i>±</i>
                                        <el-input
                                            v-model="standardFormula.slump_flaot"
                                            style="width:1.2rem"
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">砂率(%)：</span>
                                        <el-input
                                            v-model="standardFormula.sand_rate"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">水灰比：</span>
                                        <el-input
                                            v-model="standardFormula.water_cement_ratio"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">细度模数：</span>
                                        <el-input
                                            v-model="standardFormula.fineness_modulus"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">搅拌时间(s):</span>
                                        <el-input
                                            v-model="standardFormula.mixing_time"
                                            placeholder="请输入内容"
                                        ></el-input>
                                    </li>
                                    <li>
                                        <span class="item_title">养护方式：</span>
                                        <el-select
                                            v-model="standardFormula.curing"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in curingData"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                </ul>
                            </div>
                        </el-col>
                        <!-- <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <ul>
                                </ul>
                            </div>
                        </el-col> -->
                    </el-row>
                </div>
                <div class="matching_situation_wrapper">
                    <p>关联配比情况</p>
                    <div>
                        <el-table
                            :data="mixstationlineData"
                            border
                            style="width: 100%"
                            :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                        >
                            <el-table-column
                                prop="line_name"
                                label="生产机组"
                            ></el-table-column>
                            <el-table-column
                                prop="line_experiment_formula_number"
                                label="配比编号"
                                show-overflow-tooltip
                            ></el-table-column>
                            <el-table-column label="是否关联">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.line_experiment_formula_number">
                                        <i
                                            class="el-icon-circle-check"
                                            style="color:#1577D2;font-weight:800"
                                        ></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="main_bottom">
                <div class="main_header">
                    <div class="production_wrapper">
                        <span class="production_span">生产机组：</span>
                        <div class="radio_list">
                            <el-radio-group v-model="mixstationChecked">
                                <el-radio
                                    v-for="item in mixstationlineData"
                                    :key="item.line_code"
                                    :label="item.line_code"
                                    border
                                    @change="getClickTable"
                                >
                                    {{ item.line_name }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="btn_wrapper">
                        <!-- <el-button type="primary">
                            查看理论配比
            </el-button>-->
                        <!-- <el-button
                            type="primary"
                            @click="saveLineSync"
                            :disabled="!standardFormula.experiment_formula_number"
                        >
                            同步
                        </el-button> -->
                        <el-button
                            type="primary"
                            @click="lineSave"
                            :disabled="!standardFormula.experiment_formula_number"
                        >
                            保存
                        </el-button>
                    </div>
                </div>
                <div class="main_content">
                    <div class="content_left">
                        <el-table
                            :data="tableData"
                            border
                            :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                            height="2.8rem"
                            :cell-style="cellStyle"
                        >
                            <el-table-column
                                prop="plcw"
                                label="工控编号"
                            ></el-table-column>
                            <el-table-column label="工控料仓类型">
                                <template slot-scope="scope">
                                    <span @click="JumpChange(scope.row)">{{ scope.row.plcwname }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="原材料名称">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.stock_category_name }}</span>
                                    <input
                                        type="hidden"
                                        name
                                        v-model="scope.row.stock_category"
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column label="原材料子类">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.stock_type === 'F3-01-WL'">{{ scope.row.material_child_name }}</span>
                                    <div v-if="scope.row.stock_type === 'F3-01-XN'">
                                        <div v-if="scope.row.stock_category_dictcode === '203'">
                                            <!-- <div
                                            @click="materialChildSelect(scope.$index,scope.row.stock_code,scope.row.stock_category_dictcode)">
                                                {{ scope.row.material_child_name }}<i class="el-icon-edit"></i>
                                            </div> -->
                                            <div>{{ scope.row.material_child_name }}</div>
                                        </div>
                                        <div v-else>
                                            <div>{{ scope.row.material_child_name }}</div>
                                        </div>
                                    </div>
                                    <input
                                        type="hidden"
                                        name
                                        v-model="scope.row.material_child_dictcode"
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="规格"
                                show-overflow-tooltip
                            >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.stock_type === 'F3-01-WL'">{{ scope.row.material_name }}</span>
                                    <div v-if="scope.row.stock_type === 'F3-01-XN'">
                                        <div v-if="scope.row.stock_category_dictcode === '203'">
                                            <!-- <div @click="materialModelSelect(scope.$index,scope.row.material_child_dictcode)">
                                                {{ scope.row.material_name }}<i class="el-icon-edit"></i>
                                            </div> -->
                                            <div @click="materialChildSelect(scope.$index,scope.row.stock_code,scope.row.stock_category_dictcode)">
                                                {{ scope.row.material_name }}<i class="el-icon-edit"></i>
                                            </div>
                                        </div>
                                        <div v-else-if="scope.row.stock_category_dictcode === '204'">
                                            <div @click="materialChildSelect(scope.$index,scope.row.stock_code,scope.row.stock_category_dictcode)">
                                                {{ scope.row.material_name }}<i class="el-icon-edit"></i>
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{ scope.row.material_name }}
                                        </div>
                                    </div>
                                    <input
                                        type="hidden"
                                        name
                                        v-model="scope.row.material_code"
                                    >
                                </template>
                            </el-table-column>
                            <!-- <el-table-column label="设计用量">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.design_quantity" placeholder="请输入"></el-input>
                                </template>
                            </el-table-column> -->
                            <el-table-column label="实验用量">
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.experiment_quantity"
                                        @input="experimentQuantityChange"
                                        @mousewheel.native.prevent
                                        placeholder="请输入"
                                        min="0"
                                        type="number"
                                    ></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="含水率">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.stock_category_dictcode === '203' || scope.row.stock_category_dictcode === '204'">
                                        <el-input
                                            v-model="scope.row.moisture_ratio"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                    <span v-else>{{ scope.row.moisture_ratio }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="差值">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.stock_category_dictcode === '203' || scope.row.stock_category_dictcode === '204'">
                                        <el-input
                                            v-model="scope.row.moisture_ratio_difference"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                    <span v-else>{{ scope.row.moisture_ratio_difference }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="含石率">
                                <template slot-scope="scope">
                                    <el-input
                                        v-if="scope.row.stock_category_dictcode === '203'"
                                        v-model="scope.row.stone_rate"
                                        @mousewheel.native.prevent
                                        placeholder="请输入"
                                        min="0"
                                        type="number"
                                    >
                                    </el-input>
                                    <span v-else>{{ scope.row.stone_rate }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="差值">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.stock_category_dictcode === '203'">
                                        <el-input
                                            v-model="scope.row.stone_rate_difference"
                                            @mousewheel.native.prevent
                                            min="0"
                                            type="number"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                    <span v-else>{{ scope.row.stone_rate_difference }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="content_rigth">
                        <ul>
                            <li class="sp1">
                                <span style="text-align: center">设计容量</span>
                                <p>
                                    {{ standardFormula.design_volume_weight || 0 }}
                                    <i>kg</i>
                                </p>
                            </li>
                            <li class="sp2">
                                <span style="text-align: center">坍落度</span>
                                <p>
                                    {{ slumpNumber }}
                                    <i>mm</i>
                                </p>
                            </li>
                            <li class="sp3">
                                <span style="text-align: center">水胶比</span>
                                <p>
                                    {{ standardFormula.water_binder_ratio || 0 }}
                                    <i>%</i>
                                </p>
                            </li>
                            <li class="sp4">
                                <span style="text-align: center">砂率</span>
                                <p>
                                    {{ standardFormula.sand_rate || 0 }}
                                    <i>%</i>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible1"
            title="选择原材料规格"
            width="40%"
            :modal="false"
            :close-on-click-modal="false"
            center
            @close="closeDialogChange"
        >
            <el-table
                :data="materialChildSelectData"
                border
                style="width: 100%;"
                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
            >
                <el-table-column
                    label=""
                    width="55"
                >
                    <template slot-scope="scope">
                        <input
                            type="radio"
                            name="name"
                            v-model="materialChildValue"
                            :value="scope.row.material_name"
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="material_name"
                    label="名称"
                ></el-table-column>
            </el-table>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogVisible1 = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="materialChildSelectChange"
                >确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisible2"
            width="40%"
            :modal="false"
            :close-on-click-modal="false"
            center
            @close="closeDialogChange"
        >
            <el-table
                :data="materialModelSelectData"
                border
                height="3rem"
                style="width: 100%;"
                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
            >
                <el-table-column
                    label=""
                    width="55"
                >
                    <template slot-scope="scope">
                        <input
                            type="radio"
                            name="name"
                            v-model="materialModelValue"
                            :value="scope.row.name"
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="名称"
                ></el-table-column>
            </el-table>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="materialModelSelectChange"
                >确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisible3"
            width="40%"
            :modal="false"
            :close-on-click-modal="false"
            center
            @close="closeDialogChange"
        >
            <el-table
                :data="mixstationlineData"
                border
                height="3rem"
                style="width: 100%;"
                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="55"
                    :selectable="checkSelectable"
                >
                </el-table-column>
                <el-table-column
                    prop="line_name"
                    label="生产机组"
                ></el-table-column>
            </el-table>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogVisible3 = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="determineSync"
                >确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import store from '@/store';

export default {
    // 实验配比
    name: 'matching-library',
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    computed: {
        slumpNumber() {
            if (this.standardFormula.slump && this.standardFormula.slump_flaot) {
                return this.standardFormula.slump + '±' + this.standardFormula.slump_flaot;
            }
            return '0';
        },
    },
    data() {
        return {
            // 产品标识数据
            ProductIdentification: {
                // 强度等级
                qddj: {
                    checked: false,
                    options: [],
                },
                // 抗渗等级
                ksdj: {
                    checked: false,
                    options: [],
                },
                // 抗冻等级
                kddj: {
                    checked: false,
                    options: [],
                },
                // 抗折要求
                kzyq: {
                    checked: false,
                    options: [],
                },
                // 特殊要求
                tsyq: {
                    checked: false,
                    options: [],
                },
            },
            // 配方类型
            formula_typeData: [
                {
                    label: '混凝土',
                    value: 0,
                },
                {
                    label: '砂浆',
                    value: 1,
                },
                {
                    label: '润泵砂浆',
                    value: 3,
                },
            ],
            // 施工季节下拉数据
            seasonData: [
                {
                    label: '春',
                    value: 1,
                },
                {
                    label: '夏',
                    value: 2,
                },
                {
                    label: '秋',
                    value: 3,
                },
                {
                    label: '冬',
                    value: 4,
                },
            ],
            // 浇捣方式下拉数据
            pouredData: [
                {
                    label: '机械',
                    value: 1,
                },
            ],
            // 养护方式下拉数据
            curingData: [
                {
                    label: '标养',
                    value: 1,
                },
            ],
            // 保存实验配比基本信息
            standardFormula: {
                experiment_formula_number: '',
                poured: 1, // 浇捣方式
                curing: 1, // 养护方式
            },
            // 生产机组
            mixstationlineData: [],
            // 生产机组table
            mixstationlineTable: [],
            standardFormulaLine: [],
            tableData: [],
            // 机组选中的code
            mixstationChecked: [],
            // 原材料子料控制弹框显示隐藏
            dialogVisible1: false,
            // 原材料规格控制弹框显示隐藏
            dialogVisible2: false,
            // 原材料子料下拉列表数据
            materialChildSelectData: [],
            // 原材料规格下拉列表数据
            materialModelSelectData: [],
            // 当前操作列表index
            tableIndex: '',
            // 原材料子料弹框选中值
            materialChildValue: '',
            // 原材料规格弹框选中值
            materialModelValue: '',
            // 同步机组弹框
            dialogVisible3: false,
            // 同步选中机组
            multipleSelection: [],
            isLoading: false,
        };
    },
    created() {

    },
    mounted() {
    // 获取产品标识下面select的数据
        this.selectAll();
        // 配比编号赋值，有配比编号为编辑，没有是新增
        // 获取全部生产机组
        // this.getMixstationline();
        if (this.extr && this.extr.rowData) {
            // 初始化页面数据
            this.getInitData(this.extr.rowData.experiment_formula_number);
        } else {
            this.getInitData();
        }
    },
    methods: {
        experimentQuantityChange() {
            let total = 0;
            this.tableData.forEach(item => {
                total += Number(item.experiment_quantity ? item.experiment_quantity : 0);
            });
            this.$set(this.standardFormula, 'design_volume_weight', total.toFixed(2));
        },
        JumpChange(row) {
            this.$toast(
                {
                    title: true,
                    text: '工控与ERP关系配置',
                    type: 'eject',
                    width: '15rem',
                    t_url: 'purchaseManage/centralControlConfig',
                    extr: {
                        mixstation_code: this.$takeTokenParameters('StationId'),
                        line_code: this.mixstationChecked,
                        plcw: row.plcw,
                        scene: 'view',
                    },
                }
            );
        },
        // 弹窗关闭的回调方法
        closeDialogChange() {
            // 原材料子料下拉列表数据
            this.materialChildSelectData = [];
            // 原材料规格下拉列表数据
            this.materialModelSelectData = [];
            // 原材料子料弹框选中值
            this.materialChildValue = '';
            // 原材料规格弹框选中值
            this.materialModelValue = '';
            // 同步选中机组
            this.multipleSelection = [];
        },
        // 根据原材料子类material_child_dictcode获取原材料规格select数据
        materialModelSelect(index, code) {
            if (code) {
                this.tableIndex = index;
                this.dialogVisible2 = true;
                this.$axios
                    .get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=' + code)
                    .then(res => {
                        this.materialModelSelectData = res;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }

        },
        materialModelSelectChange() {
            if (this.materialModelValue) {
                this.dialogVisible2 = false;
                this.tableData[this.tableIndex].material_name = this.materialModelValue;
                const material_code = this.materialModelSelectData.filter(item => item.name === this.materialModelValue)[0].code;
                this.tableData[this.tableIndex].material_code = material_code;
            } else {
                this.$message.error('请至少选择一个');
            }

        },
        materialChildSelectChange() {
            if (this.materialChildValue) {
                this.dialogVisible1 = false;
                if (['203', '204'].indexOf(this.tableData[this.tableIndex].stock_category_dictcode) > -1) {
                    this.tableData[this.tableIndex].material_name = this.materialChildValue;
                    const material_child_dictcode = this.materialChildSelectData.filter(
                        item => item.material_name === this.materialChildValue)[0];
                    this.tableData[this.tableIndex].material_code = material_child_dictcode.material_code;
                    this.tableData[this.tableIndex].material_bg_code = material_child_dictcode.material_group;
                }
            } else {
                this.$message.error('请至少选择一个');
            }
        },
        // 根据料场类型stock_code获取原材料籽料select数据
        materialChildSelect(index, code) {
            if (code) {
                this.tableIndex = index;
                this.dialogVisible1 = true;
                this.$axios
                    .get('/interfaceApi/Procurement/stock/materials/stock_code/' + code)
                    .then(res => {
                        this.materialChildSelectData = res;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }


        },
        // 表格中的下拉列表根据name赋值code
        selectSssignmentChange(row) {
            this.options.forEach(item => {
                if (item.value === row.material_name) {
                    row.material_code = item.label;
                }
            });
            this.options.forEach(item => {
                if (item.value === row.material_child_name) {
                    row.material_child_code = item.label;
                }
            });
            this.options.forEach(item => {
                if (item.value === row.material_model_name) {
                    row.material_model = item.label;
                }
            });
        },
        // 同步选中机组
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 同步到其他生产线
        saveLineSync() {
            this.dialogVisible3 = true;
        },
        checkSelectable(row) {
            return row.line_code !== this.mixstationChecked;
        },
        // 确定同步到机组
        determineSync() {
            this.dialogVisible3 = false;
            const obj = {
                experiment_formula_number: this.standardFormula.experiment_formula_number,
                line_experiment_formula_number: '',
                syncTargetLineInputs: [],
            };

            const tableData = this.standardFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });

            if (tableData.length > 0) {
                obj.line_experiment_formula_number = tableData[0].line_experiment_formula_number;
            }

            this.multipleSelection.map(item => {
                obj.syncTargetLineInputs.push({
                    line_code: item.line_code,
                    line_name: item.line_name,
                });
            });
            this.$axios
                .post('/interfaceApi/production/standardformula/save_line/sync',
                    obj
                )
                .then(() => {
                    this.getInitData(this.standardFormula.experiment_formula_number);
                    this.$message.success('同步成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存生产线实验配比
        lineSave() {
            const standardFormulaLine = {
                experiment_formula_number: this.standardFormula
                    .experiment_formula_number,
            };

            const tableData = this.standardFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });

            if (tableData.length > 0) {
                standardFormulaLine.line_experiment_formula_number = tableData[0].line_experiment_formula_number;
            }

            const standardFormulaEntity = JSON.parse(
                JSON.stringify(this.tableData).replace(
                    /^version$/g,
                    'industry_silo_version'
                )
            );

            this.mixstationlineData.forEach(item => {
                if (item.line_code === this.mixstationChecked) {
                    standardFormulaLine.line_code = item.line_code;
                    standardFormulaLine.line_name = item.line_name;
                    standardFormulaLine.sort_num = item.sort_num;
                    standardFormulaLine.scbt = item.scbt;
                }
            });


            this.isLoading = true;

            this.$axios
                .post('/interfaceApi/production/standardformula/save_line/', {
                    standardFormulaLine: standardFormulaLine,
                    standardFormulaEntity: standardFormulaEntity,
                })
                .then(() => {
                    this.getInitData(this.standardFormula.experiment_formula_number);
                    this.$message.success('保存成功');
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
        // 根据机组查询列表初始化表格数据
        getClickTable(value) {
            const tableData = this.standardFormulaLine.filter(item => {
                return item.line_code === value;
            });
            if (tableData[0].line_experiment_formula_number) {
                this.tableData = tableData[0].standardFormulaEntityOutputs;
                if (this.tableData.length > 0) {
                    let total = 0;
                    this.tableData.forEach(item => {
                        total += Number(item.experiment_quantity ? item.experiment_quantity : 0);
                    });
                    this.standardFormula.design_volume_weight = total;
                } else {
                    this.standardFormula.design_volume_weight = 0;
                }
            } else {
                this.getInitTable(value);
            }
        },
        // 初始化料仓表格数据
        getInitTable(value) {
            const parmas = {
                line_code: value,
            };
            this.$axios
                .get(
                    '/interfaceApi/production/productsilo/silorelation/'
          + this.$takeTokenParameters('StationId')
          + '/'
          + parmas.line_code
                )
                .then(res => {
                    this.tableData = res;
                    if (res.length > 0) {
                        let total = 0;
                        this.tableData.forEach(item => {
                            total += Number(item.experiment_quantity ? item.experiment_quantity : 0);
                        });
                        this.standardFormula.design_volume_weight = total;
                    } else {
                        this.standardFormula.design_volume_weight = 0;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 编辑初始化页面数据
        getInitData(experiment_formula_number) {
            const experimentFormulaNumber = experiment_formula_number || '';
            this.$axios
                .get(
                    '/interfaceApi/production/standardformula/formulawithline?experiment_formula_number=' + experimentFormulaNumber
                )
                .then(res => {
                    this.standardFormula = res.standardFormula;
                    this.standardFormulaLine = res.standardFormulaLine;
                    this.mixstationlineData = res.standardFormulaLine;
                    if (experiment_formula_number) {
                        if (!this.mixstationChecked) {
                            this.mixstationChecked = this.mixstationlineData[0].line_code;
                            this.getClickTable(this.mixstationChecked);
                        }
                    }
                    if (this.standardFormula.formula_type === 1) {
                        // 获取强度等级下拉列表的数据
                        this.SelectData('005-SJ0', '0', 'qddj');
                    } else {
                        // 获取强度等级下拉列表的数据
                        this.SelectData('004-PH0', '0', 'qddj');
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        formulaTypeChange(val) {
            if (val === 1) {
                this.standardFormula.strength_grade = '';
                // 获取强度等级下拉列表的数据
                this.SelectData('005-SJ0', '0', 'qddj');
            } else {
                this.standardFormula.strength_grade = '';
                // 获取强度等级下拉列表的数据
                this.SelectData('004-PH0', '0', 'qddj');
            }
        },
        selectAll() {
            // 获取强度等级下拉列表的数据
            this.SelectData('004-PH0', '0', 'qddj');
            // 获取抗渗等级下拉列表的数据
            this.SelectData('501-KS0', '5', 'ksdj');
            // 获取抗冻等级下拉列表的数据
            this.SelectData('501-KD0', '5', 'kddj');
            // 获取抗折等级下拉列表的数据
            this.SelectData('501-KZ0', '5', 'kzyq');
            // 获取特殊要求下拉列表的数据
            this.SelectData('501-TX0', '5', 'tsyq');
        },
        SelectData(code, type, obj) {
            this.$axios
                .get(
                    '/interfaceApi/production/dictionary/dropdownlist/'
          + code
          + '/'
          + type
                )
                .then(res => {
                    if (obj === 'qddj') {
                        this.ProductIdentification.qddj.options = res;
                    } else if (obj === 'ksdj') {
                        this.ProductIdentification.ksdj.options = res;
                    } else if (obj === 'kddj') {
                        this.ProductIdentification.kddj.options = res;
                    } else if (obj === 'kzyq') {
                        this.ProductIdentification.kzyq.options = res;
                    } else if (obj === 'tsyq') {
                        this.ProductIdentification.tsyq.options = res;
                    }
                });
        },
        // 获取全部生产机组
        // getMixstationline() {
        //     this.$axios
        //         .get(
        //             '/interfaceApi/production/mixstationline/'
        //             + this.$takeTokenParameters('StationId')
        //         )
        //         .then(res => {
        //             this.mixstationlineData = res;
        //             this.mixstationlineTable = res;
        //         })
        //         .catch(error => {
        //             this.$message.error(error.ErrorCode.Message);
        //         });
        // },
        // 产品标识name赋值
        selectGet(value) {
            const _that = this;
            if (value === 'strength_grade') {
                // 强度等级
                _that.ProductIdentification.qddj.options.forEach(item => {
                    if (item.code === _that.standardFormula.strength_grade) {
                        _that.standardFormula.strength_grade_name = item.name;
                    }
                });
            } else if (value === 'impervious') {
                // 抗渗等级
                _that.ProductIdentification.ksdj.options.forEach(item => {
                    if (item.code === _that.standardFormula.impervious) {
                        _that.standardFormula.impervious_name = item.name;
                    }
                });
            } else if (value === 'antifreeze') {
                // 抗冻等级
                _that.ProductIdentification.kddj.options.forEach(item => {
                    if (item.code === _that.standardFormula.antifreeze) {
                        _that.standardFormula.antifreeze_name = item.name;
                    }
                });
            } else if (value === 'flexural') {
                // 抗折要求
                _that.ProductIdentification.kzyq.options.forEach(item => {
                    if (item.code === _that.standardFormula.flexural) {
                        _that.standardFormula.flexural_name = item.name;
                    }
                });
            } else if (value === 'special_require') {
                // 特殊要求
                _that.ProductIdentification.tsyq.options.forEach(item => {
                    if (item.code === _that.standardFormula.special_require) {
                        _that.standardFormula.special_require_name = item.name;
                    }
                });
            }
        },
        // 保存实验配比基本信息
        standardformulaSave() {
            const parms = this.standardFormula;
            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/standardformula/save/', parms)
                .then(res => {
                    this.standardFormula.experiment_formula_number = res;
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.$message.success('保存成功');
                    this.isLoading = false;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    this.isLoading = false;
                });
        },
        cellStyle({ columnIndex }) {
            if (columnIndex === 1) {
                return {
                    color: '#317FC7',
                    textDecoration: 'underline',
                };
            }
            return false;
        },
    },
};
</script>

<style lang="stylus">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance none
input[type='number']
    -moz-appearance textfield
.matchingLibrary_used
    width 100%
    background #e0e5eb
    height 100%
    display flex
    flex-direction column
    .el-dialog
        height 60% !important
    .el-table__row
        td
            padding 0
            height 0.34rem
    .el-button
        margin 0 0.4rem
    .el-dialog__wrapper
        height 100% !important
    .header_wrapper
        width 100%
        min-height 0.6rem
        background #ffffff
        padding 0.1rem
        overflow hidden
    .header_left
        float left
        .input_item
            float left
            margin-right 0.2rem
        span
            font-size 0.16rem
        .el-input
            width 2.4rem
        .el-input__inner
            height 0.36rem
            line-height 0.36rem
    .header_right
        float right
        .el-button
            width 0.6rem
            height 0.34rem
            padding 0
            font-size 0.16rem
            background #1577d2
    .main_wrapper
        display flex
        flex-direction column
        flex 1
    .main_top
        height 3.3rem
        margin 0.2rem 0
        display flex
        .product_marking_wrapper
            width 4rem
            height 100%
            background #ffffff
            padding 0.1rem 0.2rem
        p
            font-size 0.18rem
            border-left 0.04rem solid #1577D2
            padding-left 0.1rem
            font-weight 800
        ul
            li
                line-height 0.47rem
                span
                    font-size 0.16rem
                .el-select
                    width 2.4rem
        .basic_info_wrapper
            width 9rem
            height 100%
            background #ffffff
            margin 0 0.2rem
            padding 0.1rem 0.2rem
        p
            font-size 0.18rem
            border-left 0.04rem solid #1577D2
            padding-left 0.1rem
            font-weight 800
        ul
            li
                line-height 0.47rem
                .item_title
                    font-size 0.16rem
                    width 1.2rem
                    display inline-block
                .el-select
                    width 2.5rem
                .el-input
                    width 2.5rem
        .matching_situation_wrapper
            width 3.8rem
            height 100%
            background #ffffff
            padding 0.1rem 0.2rem
        p
            font-size 0.18rem
            border-left 0.04rem solid #1577D2
            padding-left 0.1rem
            font-weight 800
        .el-table
            font-size 0.16rem
            width 2.6rem
            margin-top 0.1rem
        .el-table__header-wrapper
            .el-table__header
                width 100% !important
                table-layout auto
            .cell
                padding 0
                text-align center
        .el-table__body-wrapper
        .el-table__body
            width 100% !important
            table-layout auto
        .el-table__row
            td
                height 0.34rem
                padding 0
            .cell
                padding 0
                text-align center
    .main_bottom
        flex 1
        width 100%
        background #ffffff
        padding 0.2rem 0.2rem 0 0.2rem
        display flex
        flex-direction column
        .main_header
            // display: flex;
            width 12.4rem
        .production_wrapper
            width 8rem
            float left
        .production_span
            font-size 0.16rem
            float left
            line-height 0.34rem
        .radio_list
            .el-radio
                // width 0.6rem
                height 0.34rem
                // padding 0.1rem
                // margin-right: 0.1rem;
                margin-right 0.1rem !important
                line-height 0.1rem
            .el-radio__input
                display none
            .el-radio__label
                padding 0
                width 100%
                height 100%
                line-height 0.1rem
        .is-bordered
            padding 0.1rem
        .btn_wrapper
            margin-left 0.4rem
            float right
        .el-button
            // width 0.34rem
            height 0.34rem
            padding 0.1rem
            font-size 0.16rem
            background #1577d2
        .main_content
            margin-top 0.2rem
            display flex
            flex 1
        .content_left
            width 12.4rem
        .el-table
            // width 12.4rem
            font-size 0.16rem
            height calc(100% - 0.2rem) !important
            .el-table__header-wrapper
                width 12.5rem !important
            .el-table__header
                width 12.5rem !important
                table-layout inherit
            .cell
                padding 0
                text-align center
            .el-table__body-wrapper
            .el-table__body
                width 12.5rem !important
                table-layout inherit
            .el-table__row
                td
                    height 0.34rem
                    padding 0
                .cell
                    padding 0
                    text-align center
                    // .el-input__inner
                    // border 1px solid transparent
            .el-table__empty-block
                width 12.5rem !important
        .content_rigth
            margin-left 0.2rem
            ul
                li
                    width 3rem
                    height 0.5rem
                    border 1px solid rgba(215, 215, 215, 1)
                    margin-bottom 0.2rem
                    span
                        font-size 0.18rem
                        line-height 0.5rem !important
                        display block
                        height 0.5rem
                        width 1rem
                        float left
                    p
                        float right
                        line-height 0.5rem !important
                        font-size 0.32rem
                        margin-right 0.1rem
                    i
                        font-size 0.16rem
                        color rgba(215, 215, 215, 1)
                .sp1
                    span
                        background #5588f12e
                        color #5588F1
                    p
                        color #5588F1
                .sp2
                    span
                        background #eb656f7a
                        color #EB656F
                    p
                        color #EB656F
                .sp3
                    span
                        background #21bdcc75
                        color #21BDCC
                    p
                        color #21BDCC
                .sp4
                    margin-bottom 0
                    span
                        background #fa843657
                        color #FA8436
                    p
                        color #FA8436
</style>